import React, { useEffect, useState } from "react";
import CompanyService from "../app/service/CompanyService";
import UserService from "../app/service/UserService";
import UsersTable from "../component/UsersTable";
import "../css/Manager.css";
import { Button } from "react-bootstrap";
function Manager() {
  const companyService = new CompanyService();
  const userService = new UserService();
  const [companyToken, setCompanyToken] = useState("");
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState([]);
  const [user, setUser] = useState({
    id: null,
    name: "",
    username: "",
    extensionUrl: "",
    extension: "",
  });
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = () => {
    companyService.listUsers().then((response) => {
      setCompanyToken(response.data.companyToken);
      setUsers(response.data);
      setFilter(response.data);
    });
  };

  const deleteUser = (userId) => {
    userService.deleteUser(userId).then(() => getData());
  };

  const toggleUserStatus = (userId) => {
    userService.toggleStatus(userId).then(() => getData());
  };

  const saveUser = (userData) => {
    if (userData.id == null) {
      userService.createUser(userData).then(() => getData());
    } else {
      userService.editUser(userData).then(() => getData());
    }
  };

  const getUser = (userId) => {
    userService
      .getUser(userId)
      .then((response) => {
        setUser({
          id: response.data.id,
          name: response.data.name,
          username: response.data.username,
          password: null,
          extensionUrl: response.data.extensionUrl,
          role: response.data.role,
          extension: response.data.extension,
        });
      })
      .catch(() => console.log("Azedou"));
  };

  const getRequisitionExample = () => {
    const url = "http://localhost:8091/swagger-ui/index.html";
    window.open(url, "_blank");
  };
  const [userData, setUserData] = useState({
    username: "",
    password: "",
  });
  return (
    <div className="container">
      <h1 style={{ marginTop: "15px" }}>Gestão</h1>
      {companyToken && (
        <div className="token">
          <h6>Token: {companyToken}</h6>
          <Button onClick={getRequisitionExample}>Requisição</Button>
        </div>
      )}
      <hr />
      <div id="table-container">
        <UsersTable
          toggleUserStatus={toggleUserStatus}
          deleteUser={deleteUser}
          users={users}
          filter={filter}
          setFilter={setFilter}
          saveUser={saveUser}
          user={user}
          setUser={setUser}
          getUser={getUser}
          userData={userData}
          setUserData={setUserData}
        />
      </div>
    </div>
  );
}

export default Manager;
