import React from "react";
import { useNavigate } from "react-router-dom";

const ExitButton = () => {
  const navigate = useNavigate();

  const handleExit = () => {
    localStorage.removeItem("_authenticated_user");
    navigate("/");
  };

  return (
    <div id="exit-button" className="exitButton" onClick={handleExit}>
      <i className="fa-solid fa-arrow-left"></i>
    </div>
  );
};

export default ExitButton;
