import ApiService from "../ApiService";
class UserService extends ApiService {
  constructor() {
    super("/usuario");
  }

  deleteUser(userId) {
    return this.delete("/" + userId);
  }

  toggleStatus(userId) {
    return this.patch("/" + userId, "");
  }

  createUser(userData) {
    return this.post("", userData);
  }

  editUser(userData) {
    return this.patch("", userData);
  }

  getUser(userId) {
    return this.get("/" + userId);
  }

  changePassword(userData, login) {
    return this.patch("/senha/" + login, userData);
  }

  getCalls(startDate) {
    return this.get("/chamadas/" + startDate);
  }
}
export default UserService;
