import ApiService from "../ApiService";
class ClickToCallService extends ApiService {
  constructor() {
    super("/chamar");
  }
 
  requestCall(destination){
    return this.get("?destino="+destination)
  }
}
export default ClickToCallService;
