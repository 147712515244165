import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import NotFound from "./view/NotFound";
import Login from "./view/Login";
import AuthService from "./app/service/AuthService";
import Agent from "./view/Agent";
import Manager from "./view/Manager";

const authService = new AuthService();

function AuthenticatedRoute({ children }) {
  return authService.isUserAuthenticated() ? (
    children
  ) : (
    <>
      {localStorage.setItem("redirectAfterLogin", window.location.pathname + window.location.search)}
      <Navigate to="/" replace />
    </>
  );
}

function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        path="/agente"
        element={
          <AuthenticatedRoute>
            <Agent />
          </AuthenticatedRoute>
        }
      />
      <Route
        path="/gestor"
        element={
          <AuthenticatedRoute>
            <Manager />
          </AuthenticatedRoute>
        }
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default AppRoutes;