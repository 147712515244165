import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import Swal from "sweetalert2";
import UserService from "../app/service/UserService";

function ChangePasswordModal({ show, toggle, login, adminChange }) {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [match, setMatch] = useState(true);
  const userService = new UserService();

  const verifyPasswords = () => {
    if (newPassword === confirmNewPassword) {
      setMatch(true);
    } else {
      setMatch(false);
    }
  };

  const handleChangePassword = () => {
    if (currentPassword === "" && !adminChange) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Senha atual deve ser preenchida",
      });
    } else if (newPassword === "") {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Nova senha deve ser preenchida",
      });
    } else if (confirmNewPassword === "") {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Confirmação da nova senha deve ser preenchida",
      });
    } else {
      verifyPasswords();
      if (match) {
        saveNewPassword();
      } else {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "As senhas não coincidem!",
        });
      }
    }
  };

  const saveNewPassword = () => {
    const userData = {
      currentPassword: currentPassword,
      newPassword: newPassword,
      confirmNewPassword: confirmNewPassword,
    };

    var sendLogin = login;
    if (login === null) sendLogin = localStorage.getItem("_username");
    userService
      .changePassword(userData, sendLogin.replaceAll(".", "-"))
      .then(() => {
        toggle();
        Swal.fire({
          icon: "success",
          title: "Sucesso",
          text: "Senha alterada!",
          confirmButtonText: "OK",
        });
      })
      .catch((error) => {
        if (error.response.status === 400) {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: error.response.data.error,
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: "Entre em contato com o suporte!",
            confirmButtonText: "OK",
          });
        }
      });
  };

  const clear = () => {
    setConfirmNewPassword("");
    setCurrentPassword("");
    setNewPassword("");
  };

  return (
    <Modal
      show={show}
      onHide={toggle}
      onShow={clear}
      size="lg"
      data-bs-theme="dark"
      className="modal-light"
    >
      <Modal.Header closeButton>
        <Modal.Title>Alterar Senha</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div className="row">
            {!adminChange && (
              <div className="col">
                <Form.Group controlId="formCurrentPassword">
                  <Form.Label>Senha atual</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Digite sua senha atual"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                </Form.Group>
              </div>
            )}

            <div className="col">
              <Form.Group controlId="formNewPassword">
                <Form.Label>Nova senha</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Digite sua nova senha"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group controlId="formConfirmNewPassword">
                <Form.Label>Confirmar nova senha</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Confirme sua nova senha"
                  value={confirmNewPassword}
                  onChange={(e) => setConfirmNewPassword(e.target.value)}
                  onKeyUp={() => verifyPasswords()}
                />
              </Form.Group>
            </div>
          </div>
          {!match && (
            <div
              style={{ color: "red", marginTop: "10px", textAlign: "center" }}
            >
              As senhas não coincidem
            </div>
          )}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleChangePassword}>
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ChangePasswordModal;
