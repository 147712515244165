import React from "react";

import "../css/NotFound.css";

function NotFound() {
  return (
    <>
      <div id="notfound">
        <div className="notfound">
          <div className="notfound-404">
            <h1>404</h1>
            <h2>Página não encontrada</h2>
          </div>
          <a href="/">Voltar ao site</a>
        </div>
      </div>
    </>
  );
}

export default NotFound;
