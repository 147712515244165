import ApiService from "../ApiService";
class CompanyService extends ApiService {
  constructor() {
    super("/empresa");
  }

  listUsers() {
    return this.get("");
  }

}
export default CompanyService;
