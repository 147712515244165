import React, {useState, useEffect} from "react";
import "../css/Agent.css";
import UserService from "../app/service/UserService";
import ExtensionIframe from "../component/ExtensionIframe";
import CallsTable from "../component/CallsTable";
import { FormControl } from "react-bootstrap";



function Agent() {
  const [calls, setCalls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [startDate, setStatDate] = useState(new Date().toISOString().slice(0, 10));

  const userService = new UserService();
  useEffect(() => {
    /* eslint-disable react-hooks/exhaustive-deps */
    getData();
  }, [startDate]);

  const getData = () => {
    setLoading(true)
    if ((startDate !== "")) {
      userService.getCalls(startDate).then((response) => {
        const data = response.data;
        setCalls(data);
        setLoading(false);
      });
    }
  };
  return (
    <div
      className="d-flex d-column justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div
        className="bg-dark"
        style={{ height: "100%", width: "63%", padding: "20px" }}
      >
        <div className="agent-header">
          <h1>Minhas chamadas</h1>{" "}
          <div className="agent-call-reload" onClick={getData}>
            <i className="fa-solid fa-rotate-right"></i>
          </div>
          <FormControl
          data-bs-theme="dark"
          type="date"
          style={{width: '150px', marginLeft: 'auto'}}
          value={startDate}
          onChange={e => setStatDate(e.target.value)}
          />
        </div>
        <CallsTable calls={calls} loading={loading}/>
      </div>
      <ExtensionIframe />
    </div>
  );
}

export default Agent;
