import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import "../css/UsersTable.css";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import UserModal from "./UserModal";
import ChangePasswordModal from "./ChangePasswordModal";
function UsersTable({
  users,
  setFilter,
  filter,
  deleteUser,
  toggleUserStatus,
  getUser,
  saveUser,
  user,
  setUser,
  userData,
  setUserData
}) {
  const [search, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(null);
  const [login, setLogin] = useState("");
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)


  const toggleCpModal = (type) => {
    if (show) {
      clearUserData();
    }
    setShowChangePasswordModal((prev) => !prev);
  };


  const clearUserData = () => {
    setUser({
      username: "",
      password: "",
    });
  };

  const toggleModal = (type) => {
    if (show) {
      clearUser();
    }
    if (type === "CREATE") {
      setEdit(false);
    } else {
      setEdit(true);
    }

    setShow((prev) => !prev);
  };

  const clearUser = () => {
    setUser({
      id: null,
      name: "",
      username: "",
      extensionUrl: "",
      extension: "",
    });
  };

  const columns = [
    {
      name: "Nome",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Usuário",
      selector: (row) => row.username,
      sortable: true,
    },
    {
      name: "Ramal",
      selector: (row) => row.extension,
      sortable: true,
    },
    {
      name: "Ativo",
      selector: (row) =>
        row.status ? (
          <Button variant="success" onClick={() => handleToggleStatus(row.id)}>
            <i className="fa-solid fa-check"></i>
          </Button>
        ) : (
          <Button variant="danger" onClick={() => handleToggleStatus(row.id)}>
            <i className="fa-solid fa-x"></i>
          </Button>
        ),
    },
    {
      name: "Senha",
      selector: (row) => (
        <Button onClick={() => handleCp(row.username)} variant="warning">
          <i className="fa-solid fa-key"></i>
        </Button>
      ),
    },
    {
      name: "Editar",
      selector: (row) => (
        <Button onClick={() => toggleEdit(row.id)} className="passButton">
          <i className="fa-solid fa-pencil"></i>
        </Button>
      ),
    },
    {
      name: "Excluir",
      selector: (row) => (
        <Button variant="danger" onClick={() => handleDelete(row.id)}>
          <i className="fa-solid fa-trash-can"></i>
        </Button>
      ),
    },
  ];

  const handleDelete = (userId) => {
    Swal.fire({
      icon: "warning",
      title: "Tem certeza que deseja excluir este usuário?",
      showDenyButton: true,
      confirmButtonText: "Sim",
      denyButtonText: "Não",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteUser(userId);
      }
    });
  };

  const handleToggleStatus = (userId) => {
    toggleUserStatus(userId);
  };

  const paginationComponentOptions = {
    rowsPerPageText: "Itens por página",
    rangeSeparatorText: "de",
    noRowsPerPage: false,
    selectAllRowsItem: true,
    selectAllRowsItemText: "Todos",
  };

  useEffect(() => {
    const result = users.filter((item) => {
      const searchLower = search.toLowerCase();
      const name = item.name.toLowerCase();
      const username = item.name.toLowerCase();
      const extension = item.extension;

      return (
        name.includes(searchLower) ||
        username.includes(searchLower) ||
        extension.toString().includes(search)
      );
    });

    setFilter(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const CustomNoDataComponent = () => (
    <div className="noData">
      <p>Nenhum dado encontrado!</p>
    </div>
  );

  const toggleEdit = (userId) => {
    getUser(userId);
    toggleModal("EDIT");
  };

  const handleCp = (username) =>{
    setLogin(username);
    toggleCpModal();
  }

  return (
    <>
      <DataTable
        columns={columns}
        data={filter}
        pagination
        paginationComponentOptions={paginationComponentOptions}
        fixedHeader
        subHeader
        subHeaderComponent={
          <div className="table-header-container d-flex justify-content-between">
            <h4>Usuários</h4>
            <div style={{ width: "fit-content" }} className="d-flex">
              <Button
                onClick={() => toggleModal("CREATE")}
                variant="success"
                style={{ marginRight: "30px" }}
              >
                Criar usuário
              </Button>
              <input
                type="text"
                className="form-control table-search"
                data-bs-theme="dark"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
                placeholder="Pesquisar"
              />
            </div>
          </div>
        }
        noDataComponent={<CustomNoDataComponent />}
      />
      <UserModal
        show={show}
        user={user}
        setUser={setUser}
        toggleModal={toggleModal}
        edit={edit}
        saveUser={saveUser}
      />
      <ChangePasswordModal
        show={showChangePasswordModal} toggle={toggleCpModal} login={login} adminChange={true}
      />
    </>
  );
}

export default UsersTable;
