import React from "react";
import { BrowserRouter} from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./css/AppDark.css"
import AppRoutes from "./Routes";
import ExitButton from "./component/ExitButton";
import ChangePasswordButton from "./component/ChangePassowordButton";


const App = () => {

  return (
    <BrowserRouter>
    <link rel="stylesheet" href="./style.css"></link>
      <AppRoutes />
      <ExitButton/>
      <ChangePasswordButton/>
    </BrowserRouter>
  );
};

export default App;
