import React, { useMemo }  from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "primeflex/primeflex.css";
import "primereact/resources/primereact.css";
import "../css/TicketTable.css";
import "primereact/resources/themes/viva-dark/theme.css";
import ClickToCallService from "../app/service/ClickToCallService";
export default function CallsTable({ calls, loading }) {
  const callService = useMemo(() => new ClickToCallService(), []);

  const formatPhone = (rowData) => {
    const phone = rowData.destination.slice(2);

    if (phone.length === 11) {
      const ddd = phone.substring(0, 2);
      const parte1 = phone.substring(2, 7);
      const parte2 = phone.substring(7);

      return `(${ddd}) ${parte1}-${parte2}`;
    } else if (phone.length === 10) {
      const ddd = phone.substring(0, 2);
      const parte1 = phone.substring(2, 6);
      const parte2 = phone.substring(6);

      return `(${ddd}) ${parte1}-${parte2}`;
    }

    return phone;
  };

  const recordDownload = (rowData) => {
    const url = rowData.recordUrl;
    return (
      <a className="btn btn-primary" href={url}>
        <i className="fa-solid fa-download"></i>
      </a>
    );
  };

  const call = (rowData) => {
    return (
      <div className="btn btn-success" onClick={()=> requestCall(rowData.destination)}>
        <i className="fa-solid fa-phone"></i>
      </div>
    );
  };

  const requestCall = (destination) => {
    if (destination) {
      callService.requestCall(destination);
    }
  };

  return (
    <div className="card" style={{ marginBottom: "50px" }}>
      <DataTable
        stripedRows
        value={calls}
        paginator
        rows={10}
        dataKey="id"
        removableSort
        loading={loading}
        emptyMessage="Nenhuma chamada encontrada!"
      >
        <Column field="date" header="Data" />
        <Column field="duration" header="Duração" />
        <Column field="destination" header="Destino" body={formatPhone} />
        <Column field="recordUrl" header="Gravação" body={recordDownload} />
        <Column header="Ligar" body={call} />
      </DataTable>
    </div>
  );
}
