import { useEffect, useState } from "react";
import ChangePasswordModal from "./ChangePasswordModal";
import { jwtDecode } from "jwt-decode";

export default function ChangePasswordButton() {
    const[show, setShow] = useState(false);
    const toggle = () =>{
        setShow(prev=> !prev);
    }
    const [login, setLogin] = useState("");

   

    const decodeJWT = (token) => {
        try {
          return jwtDecode(token);
        } catch (error) {
          console.error("Failed to decode JWT:", error);
          return null;
        }
      };
    
      useEffect(()=>{
        const decodedUser = decodeJWT(localStorage.getItem("_authenticated_user"));
        if(decodedUser!= null){setLogin(decodedUser.sub);}
      },[])
  return (
    <>
      <div id="change-password-button" onClick={toggle}>
        <i className="fa-solid fa-key"></i>
      </div>
      <ChangePasswordModal show={show} toggle={toggle} login={login} adminChange={false}/>
    </>
  );
}
