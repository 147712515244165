import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import Swal from "sweetalert2";

function UserModal({ show, toggleModal, setUser, user, edit, saveUser }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (user.name === "") {
      Swal.fire({
        icon: "error",
        title: "Campo obrigatório",
        text: "O campo nome não pode estar vazio.",
      });
    } else if (user.username === "") {
      Swal.fire({
        icon: "error",
        title: "Campo obrigatório",
        text: "O campo usuário não pode estar vazio.",
      });
    } else if (!edit && user.password === "") {
      Swal.fire({
        icon: "error",
        title: "Campo obrigatório",
        text: "O campo senha não pode estar vazio.",
      });
    } else if (user.extensionUrl === "") {
      Swal.fire({
        icon: "error",
        title: "Campo obrigatório",
        text: "O campo link ramal web não pode estar vazio.",
      });
    } else if (user.extension === "" || isNaN(user.extension)) {
      Swal.fire({
        icon: "error",
        title: "Campo inválido",
        text: "O campo número do ramal deve ser um número válido.",
      });
    } else {
      saveUser(user);
      console.log(user);
      toggleModal();
    }
  };

  return (
    <Modal show={show} onHide={toggleModal} size="lg" data-bs-theme="dark" className="modal-light">
      <Modal.Header closeButton>
        <Modal.Title>Usuário</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Control type="hidden" value={user.id} />
          <Form.Group controlId="formName" className="input-user-modal">
            <Form.Label>Nome</Form.Label>
            <Form.Control
              type="text"
              name="name"
              value={user.name}
              onChange={handleChange}
              placeholder="Insira o nome"
            />
          </Form.Group>
          <div className="row">
            <div className="col">
              <Form.Group controlId="formUsername" className="input-user-modal">
                <Form.Label>Usuário</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  value={user.username}
                  onChange={handleChange}
                  placeholder="Insira o usuário"
                />
              </Form.Group>
            </div>
            {!edit && (
              <div className="col">
                <Form.Group
                  controlId="formPassword"
                  className="input-user-modal"
                >
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    value={user.password}
                    onChange={handleChange}
                    placeholder="Insira a senha"
                  />
                </Form.Group>
              </div>
            )}
          </div>

          <div className="row">
            <div className="col">
              <Form.Group
                controlId="formExtensionUrl"
                className="input-user-modal"
              >
                <Form.Label>Link Ramal Web</Form.Label>
                <Form.Control
                  type="text"
                  name="extensionUrl"
                  value={user.extensionUrl}
                  onChange={handleChange}
                  placeholder="Insira o link do ramal web"
                />
              </Form.Group>
            </div>
            <div className="col">
              <Form.Group
                controlId="formExtension"
                className="input-user-modal"
                style={{ marginBottom: "40px" }}
              >
                <Form.Label>Número do Ramal</Form.Label>
                <Form.Control
                  type="number"
                  name="extension"
                  value={user.extension}
                  onChange={handleChange}
                  placeholder="Insira o número do ramal"
                />
              </Form.Group>
            </div>
          </div>

          <Modal.Footer>
            <Button variant="primary" type="submit">
              Enviar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default UserModal;
