import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useLocation } from "react-router-dom";
import ClickToCallService from "../app/service/ClickToCallService";
import { jwtDecode } from "jwt-decode";


export default function ExtensionIframe() {
  const iframeRef = useRef(null);
  const [extensionUrl, setExtensionUrl] = useState("");
  const [destination, setDestination] = useState("");
  const location = useLocation();

  // Usar useMemo para instanciar o serviço apenas uma vez
  const service = useMemo(() => new ClickToCallService(), []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const destino = queryParams.get("destino");
    setDestination(destino || "");
  }, [location.search]);

  const requestCall = useCallback(() => {
    if (destination) {
      service.requestCall(destination);
    }
  }, [destination, service]);

  const decodeJWT = (token) => {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error("Failed to decode JWT:", error);
      return null;
    }
  };

  useEffect(() => {
    const decodedUser = decodeJWT(localStorage.getItem("_authenticated_user"));
    setExtensionUrl(decodedUser.extensionUrl);
  }, []);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (iframe) {
      iframe.onload = () => {
        if (destination) {
          requestCall();
        }
      };
    }

    return () => {
      if (iframe) {
        iframe.onload = null;
      }
    };
  }, [requestCall, destination]);

  useEffect(() => {
    const handleMessage = (e) => {
      if (e.origin !== "https://pabx.mbmpabx.com.br") return;

      console.log("Received message:", e.data);
    };

    window.addEventListener("message", handleMessage);

    const addEventsIframe = () => {
      const iframe = iframeRef.current;
      if (iframe) {
        const iframeWindow = iframe.contentWindow;
        if (iframeWindow) {
          iframeWindow.postMessage(
            window.location.href,
            "https://pabx.mbmpabx.com.br"
          );
        }
      }
    };

    const iframe = iframeRef.current;
    if (iframe) {
      iframe.onload = addEventsIframe;
    }

    return () => {
      window.removeEventListener("message", handleMessage);
      if (iframe) iframe.onload = null;
    };
  }, []);
  return (
    <>
      {extensionUrl && (
        <iframe
          title="webSoftPhone"
          src={extensionUrl}
          id="stk-telefone-container"
          name="Ramal Web"
          ref={iframeRef}
          allow="microphone"
          style={{ height: "577px", colorScheme: 'none', backgroundColor: 'transparent'}}
        ></iframe>
      )}
    </>
  );
}
