import ApiService from "../ApiService";
import {jwtDecode} from "jwt-decode";
class AuthService extends ApiService {
  constructor() {
    super("/login");
  }

  logarUsuario(username, password) {
    return this.signIn("", {
      username: username,
      password: password,
    });
  }

  isUserAuthenticated() {
    const token = localStorage.getItem("_authenticated_user");
    if (!token) {
      return false;
    }
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp && decoded.exp < currentTime) {
        return false;
      }
      return true;
    } catch (error) {
      console.error("Failed to decode token:", error);
      return false;
    }
  }
}
export default AuthService;
