import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import AuthService from "../app/service/AuthService";
import { jwtDecode } from "jwt-decode";
import Logo from "../assets/images/logo.png";
import "../css/Login.css";

const Login = () => {
  const navigate = useNavigate();
  const service = useMemo(() => new AuthService(), []);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const checkAuthentication = async () => {
      if (service.isUserAuthenticated()) {
        const token = localStorage.getItem("_authenticated_user");
        if (token) {
          const decodedUser = decodeJWT(token);
          if (decodedUser) {
            console.log('Role: ' + decodedUser.role )
            const redirectPath = decodedUser.role === "ADMIN" ? "/gestor" : "/agente";
            navigate(redirectPath);
            localStorage.removeItem("redirectAfterLogin");
          }
        }
      }
    };

    checkAuthentication();
  }, [navigate, service]);

  const signIn = useCallback(
    async (e) => {
      e.preventDefault();
      if (!username || !password) {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Campos usuário e senha não podem estar vazios",
        });
        return;
      }

      setLoading(true);

      try {
        const response = await service.logarUsuario(username, password);
        const token = response.data;
        localStorage.setItem("_authenticated_user", token);
        const decodedUser = decodeJWT(token);
        
        const redirectPath = localStorage.getItem("redirectAfterLogin") || (decodedUser.role === "ADMIN" ? "/gestor" : "/agente");
        navigate(redirectPath);
        localStorage.removeItem("redirectAfterLogin");
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: error.response?.data?.message || "Erro desconhecido",
        });
      } finally {
        setLoading(false);
      }
    },
    [username, password, service, navigate]
  );

  const decodeJWT = (token) => {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error("Failed to decode JWT:", error);
      return null;
    }
  };

  return (
    <div id="body-login">
      <style jsx="true">{`
        #toggleTheme,
        #sun,
        #moon,
        .exitButton,
        #change-password-button {
          display: none !important;
        }
      `}</style>
      <div className="img">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="content-login">
        <div className="form">
          <form onSubmit={signIn}>
            <div className="imgLogo">
              <img src={Logo} alt="Logo" />
            </div>
            <input
              placeholder="Login"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              type="text"
              id="login"
              name="login"
            />
            <input
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              id="password"
              name="password"
            />
            <button className="submit" type="submit" disabled={loading}>
              {loading ? "Entrando..." : "Enviar"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
